export const state = () => ({
  locales: [
    { code: 'is', iso: 'is-IS', name: 'Íslenska', file: 'is.json' },
    { code: 'en', iso: 'en-US', name: 'English', file: 'en.json' },
    { code: 'ua', iso: 'ua-UA', name: 'Українська', file: 'ua.json' }
  ],
  languages: [
    {
      lang: 'Íslenska',
      tag: 'is',
      locale: 'is-IS'
    },
    {
      lang: 'Українська',
      tag: 'ua',
      locale: 'ua-UA'
    },
    {
      lang: 'English',
      tag: 'en',
      locale: 'en-EN'
    }
  ],
  currentLanguage: 'is',
  fallbackLanguage: 'is',
  translations: {}
})

export const getters = {
  /* take in a tag and if the translation 
  / should start with lower-case,
  / check if it exists for current language
  / if not, fall back to fallback language
  */
  localePrice:
    (state) =>
    (price, quote = 'ISK', includeQuote = true) => {
      if (
        (price !== 0 && !price) ||
        price === undefined ||
        price === 'undefined'
      ) {
        return null
      }
      const formattedPrice = parseFloat(price).toLocaleString(
        state.languages.find((x) => x.tag === state.currentLanguage).locale
      )
      if (includeQuote) {
        if (quote === 'USD') {
          return `$${formattedPrice}`
        }
        return `${formattedPrice} kr.`
      }
      return formattedPrice
    },
  localeCompare: (state) => (a, b) => a.localeCompare(b, state.currentLanguage)
}

export const mutations = {
  setLanguages(state, languages) {
    state.languages = languages
  },
  setCurrentLanguage(state, lang) {
    state.currentLanguage = lang
  },
  addTranslation(state, { lang, translations }) {
    if (translations.length > 0) {
      state.translations[lang] = {}

      for (const translation of translations) {
        state.translations[lang][translation.tag] = translation.text
      }
    }
  }
}

export const actions = {
  fetchLanguages(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('languages/')
        .then((response) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  switchLanguage(context, lang) {
    // if language is in translations, just call setCurrentLanguage mutation
    // else call fetchLanguage
    return new Promise((resolve, reject) => {
      if (context.state.translations[lang]) {
        context.commit('setCurrentLanguage', lang)
        resolve()
      } else {
        this.$axios
          .get(`translations/`, {
            params: {
              lang
            }
          })
          .then((response) => {
            // commit
            context.commit('addTranslation', {
              lang,
              translations: response.data
            })

            context.commit('setCurrentLanguage', lang)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }
}
