export const state = () => ({
  currentCampaign: null,
  currentQuestion: null,
  currentCampaignResults: null,
  campaigns: [],
  campaignUser: {
    id: null
  },
  questionTypes: {
    MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
    SINGLE_CHOICE: 'SINGLE_CHOICE',
    TEXT: 'TEXT',
    NUMERICAL: 'NUMERICAL'
  },
  loading: false,
  startDate: null,
  endDate: null,
  timespan: 'month',
  dashboardDataSpan: 'day',
  dashboardData: null
})

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading
  },
  setCurrentCampaign(state, campaign) {
    state.currentCampaign = campaign
  },
  setCampaignUser(state, campaignUser) {
    state.campaignUser = campaignUser
  },
  setCurrentQuestion(state, question) {
    state.currentQuestion = question
  },
  setStartDate(state, value) {
    state.startDate = value
  },
  setEndDate(state, value) {
    state.endDate = value
  },
  setTimespan(state, value) {
    state.timespan = value
  },
  setDashboardData(state, value) {
    state.dashboardData = value
  },
  setDashboardDataSpan(state, value) {
    state.dashboardDataSpan = value
  },
  setCampaigns(state, value) {
    state.campaigns = value
  },
  setCurrentCampaignResults(state, value) {
    state.currentCampaignResults = value
  }
}

export const actions = {
  fetchDashboardData({ state, commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`staff/surveys/dashboard/`, {
          params: {
            start_date: state.startDate,
            end_date: state.endDate,
            span: state.dashboardDataSpan
          }
        })
        .then((response) => {
          commit('setDashboardData', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCampaigns(context) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('staff/campaigns/')
        .then((response) => {
          context.commit('setLoading', false)
          context.commit('setCampaigns', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCampaign(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`campaigns/${id}/`)
        .then((response) => {
          context.commit('setCurrentCampaign', response.data)
          context.commit('setLoading', false)
          resolve()
        })
        .catch((error) => {
          context.commit('setLoading', false)
          reject(error)
        })
    })
  },
  fetchStaffCampaign(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`staff/campaigns/${id}/`)
        .then((response) => {
          context.commit('setCurrentCampaign', response.data)
          context.commit('setLoading', false)
          resolve()
        })
        .catch((error) => {
          context.commit('setLoading', false)
          reject(error)
        })
    })
  },
  fetchStaffCampaignResults(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`staff/campaigns/${id}/results/`)
        .then((response) => {
          context.commit('setCurrentCampaignResults', response.data)
          context.commit('setLoading', false)
          resolve()
        })
        .catch((error) => {
          context.commit('setLoading', false)
          reject(error)
        })
    })
  },
  pauseCampaign(context, { id, action }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`campaigns/${id}/${action}/`)
        .then((response) => {
          context.commit('setCurrentCampaign', response.data)
          context.commit('setLoading', false)
          resolve()
        })
        .catch((error) => {
          context.commit('setLoading', false)
          reject(error)
        })
    })
  },
  duplicateCampaign(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`campaigns/${id}/duplicate/`, { data: payload })
        .then((response) => {
          context.commit('setCurrentCampaign', response.data)
          context.commit('setLoading', false)
          resolve()
        })
        .catch((error) => {
          context.commit('setLoading', false)
          reject(error)
        })
    })
  },
  addParticipant(context, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`campaigns/${id}/users/`, { data: payload })
        .then((response) => {
          alert('Participant added successfully')
          context.commit('setLoading', false)
          resolve()
        })
        .catch((error) => {
          context.commit('setLoading', false)
          reject(error)
        })
    })
  },
  validateCampaignUser(context, { id, token }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`campaigns/${id}/validate-campaign-user/`, {
          params: { token }
        })
        .then((response) => {
          context.commit('setCampaignUser', response.data)
          context.commit('setLoading', false)
          resolve()
        })
        .catch((error) => {
          context.commit('setLoading', false)
          reject(error)
        })
    })
  },
  fetchQuestion(context, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`questions/${id}/`)
        .then((response) => {
          // If the question is not in the current campaign throw an error
          if (response.data.campaign_id !== context.state.currentCampaign.id) {
            reject(new Error('Question not found in the current campaign'))
            return
          }
          context.commit('setCurrentQuestion', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  submitOptionResponse(ctx, { optionId, payload }) {
    payload.campaign_user_id = ctx.state.campaignUser.id
    if (payload.delete) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(
            `options/${optionId}/responses/?token=${ctx.state.campaignUser.id}`,
            { data: payload }
          )
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
    return new Promise((resolve, reject) => {
      this.$axios
        .post(
          `options/${optionId}/responses/?token=${ctx.state.campaignUser.id}`,
          payload
        )
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
