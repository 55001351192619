<template>
  <div class="sidebar">
    <b-menu>
      <b-menu-list>
        <b-menu-item
          :class="menuItemClass"
          :label="isExpanded ? $t('login-modal_bio') : null"
          icon="account"
          @click="handleChange({ name: 'profile-edit' })"
        ></b-menu-item>
        <b-menu-item
          :class="menuItemClass"
          :label="isExpanded ? $t('applications') : null"
          icon="playlist-edit"
          @click="handleChange({ name: 'tenant-applications' })"
        ></b-menu-item>
        <b-menu-item
          v-if="false"
          :class="menuItemClass"
          :label="isExpanded ? $t('listings-watch_saved-searches') : null"
          icon="star"
          @click="handleChange({ name: 'saved-searches' })"
        ></b-menu-item>
        <b-menu-item
          :class="menuItemClass"
          :label="isExpanded ? $t('rental-contracts') : null"
          icon="file-document-edit-outline"
          @click="handleChange({ name: 'rental-contracts' })"
        ></b-menu-item>
        <b-menu-item
          v-if="isStaff"
          :class="menuItemClass"
          :label="$t('rental-guarantees')"
          icon="shield-check-outline"
          @click="handleChange({ name: 'tenant-rental-guarantees' })"
        ></b-menu-item>
        <b-menu-item
          v-if="isStaff"
          :class="menuItemClass"
          :label="isExpanded ? $t('route_account-payout-methods') : null"
          icon="account-cash-outline"
          @click="handleChange({ name: 'account-payout-methods' })"
        ></b-menu-item>
        <b-menu-item
          :class="menuItemClass"
          :label="isExpanded ? $t('account_notifications_title') : null"
          icon="bell-outline"
          @click="handleChange({ name: 'account-notifications' })"
        ></b-menu-item>
        <b-menu-item
          :class="menuItemClass"
          :label="isExpanded ? $t('login-security') : null"
          icon="shield-lock-outline"
          @click="handleChange({ name: 'account-login-security' })"
        ></b-menu-item>
        <div v-if="isExpanded" class="is-clickable menu switch-role pt-5">
          <p @click="$emit('switchView', 'LL')">
            {{ $t('tenant_switch-view') }}
          </p>
        </div>
      </b-menu-list>
    </b-menu>
  </div>
</template>
<script>
export default {
  props: {
    isExpanded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    menuItemClass() {
      return this.isExpanded ? '' : 'collapsed'
    },
    isStaff() {
      return this.$store.state.user?.userObject?.is_staff
    }
  },
  methods: {
    handleChange(widget) {
      this.$emit('widgetChange', widget)
    }
  }
}
</script>
